import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Titles,
  VideoWrapper,
  FollowLinks,
  FLink,
} from './header.css';
import { AlbumLink, LinkContainer } from '../tnw/tnw.css';
import LauvLogo from './lauvLogo';
import Album from './albumLogo';
import Video from 'components/video';

const Header = ({ country }) => (
  <Fragment>
    <Container>
      <Titles>
        <LauvLogo />
        <Album />
      </Titles>
      <VideoWrapper top>
        <Video vidId="W775nQuxOwY" />
      </VideoWrapper>
      <LinkContainer top>
        <AlbumLink
          id="spotify_DSP_BTN"
          href="https://lauv.lnk.to/dti_spotify"
          top
        >
          Listen on Spotify
        </AlbumLink>
        <AlbumLink
          id="applemusic_DSP_BTN"
          href="https://lauv.lnk.to/tdi_applemusic"
          top
        >
          Listen on Apple Music
        </AlbumLink>
        <AlbumLink
          id="amazonmusic_DSP_BTN"
          href="https://lauv.lnk.to/tdi_amazon"
          top
        >
          Listen on Amazon
        </AlbumLink>
        <AlbumLink
          id="itunes_DSP_BTN"
          href="https://lauv.lnk.to/dti_itunes"
          top
        >
          Listen on iTunes
        </AlbumLink>
        {country && country.country_name === 'India' ? (
          <AlbumLink
            id="jiosaavn_DSP_BTN"
            href="https://www.jiosaavn.com/song/drugs--the-internet/JFAIVRlVYgY"
            top
          >
            Listen on JioSaavn
          </AlbumLink>
        ) : (
          <AlbumLink
            id="deezer_DSP_BTN"
            href="https://lauv.lnk.to/drugsinternetdeezer"
            top
          >
            Listen on Deezer
          </AlbumLink>
        )}
      </LinkContainer>
    </Container>
    <VideoWrapper bottom>
      <Video vidId="W775nQuxOwY" />

      <FollowLinks>
        <FLink href="https://lauv.lnk.to/appleil" id="apple_Social_BTN">
          Follow On Apple Music
        </FLink>
        <FLink
          href="https://lauv.lnk.to/spotifysubscribeil"
          id="spotify_Social_BTN"
        >
          Follow On Spotify
        </FLink>
      </FollowLinks>
    </VideoWrapper>
  </Fragment>
);

Header.propTypes = {
  country: PropTypes.object.isRequired,
};

export default Header;
