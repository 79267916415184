import styled from 'styled-components';

export const Vid = styled.div`
  border: 10px solid #fff;
  width: 100%;
  margin: 0 auto;
  max-width: ${({ small }) => (small ? '700px' : '1180px')};
  box-sizing: border-box;
  background: #fff;

  * {
    box-sizing: border-box;
  }
`;

export const Title = styled.div`
  font-size: 2em;
  margin: 30px 0 60px;
`;

export const Thumb = styled.img`
  width: 100%;
`;

export const ThumbWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
