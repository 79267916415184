import React, { Fragment } from 'react';
import YouTube from 'react-youtube-embed';
import { Vid, Title } from './video.css';

class VideoPlayer extends React.Component {
  render() {
    const { vidId, text } = this.props;
    return (
      <Fragment>
        <Vid small={this.props.small}>
          <YouTube id={vidId} autoplay />
        </Vid>

        {text && <Title>{text}</Title>}
      </Fragment>
    );
  }
}

export default VideoPlayer;
