import React from 'react';
import styled from 'styled-components';
import Logo from '../../../../static/lauvlogo.svg';

const Wrapper = styled.div`
  @media (max-width: 700px) {
    width: 28.28947368%;
    img {
      width: 100%;
    }
  }
`;

const LauvLogo = () => {
  return (
    <Wrapper>
      <img src={Logo} alt="lauv logo" />
    </Wrapper>
  );
};

export default LauvLogo;
