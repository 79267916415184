import React from 'react';
import styled from 'styled-components';
import DrugsAndInternet from '../../../../static/drugs_and_internet.svg';

const Wrapper = styled.div`
  @media (max-width: 700px) {
    width: 59.21052632%;
    img {
      width: 100%;
    }
  }
`;

const Album = () => {
  return (
    <Wrapper>
      <img src={DrugsAndInternet} alt="Drugs &amp; The Internet" />
    </Wrapper>
  );
};

export default Album;
