import styled, { css } from 'styled-components';
import BG from '../../../static/headerbg.jpg';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  background-image: url(${BG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 5px solid #fff;
  height: 740px;
  padding: 4rem;

  @media (max-width: 700px) {
    height: auto;
    background-size: cover;
  }

  a {
    color: #757575;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: inherit;
    }
  }
`;

export const Titles = styled.div`
  max-width: 1180px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  @media (max-width: 700px) {
    margin: 0 auto 60px;
  }
`;

export const VideoWrapper = styled.div`
  position: absolute;
  width: 100%;
  top: 370px;
  max-width: 1180px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  ${({ top }) =>
    top &&
    css`
      display: none;
    `};

  @media (max-width: 1240px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 700px) {
    ${({ bottom }) =>
      bottom &&
      css`
        display: none;
      `} ${({ top }) =>
      top &&
      css`
        position: static;
        display: block;
        margin-top: 40px;
        padding: 0;
        margin-top: 22px;
      `};
  }
`;

export const FollowLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
  padding: 0 10px;

  @media (max-width: 700px) {
    display: none;
  }

  ${({ bottom }) =>
    bottom &&
    css`
      display: none;
      @media (max-width: 700px) {
        display: block;
      }
    `};
`;

export const FLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 1.4em;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  padding: 8px 10px 7px;

  @media (max-width: 700px) {
    width: 100%;
    text-align: center;
    display: none;
  }

  &:hover {
    color: #db96b7;
    background: #fff;
  }

  ${({ bottom }) =>
    bottom &&
    css`
      display: none;
      @media (max-width: 700px) {
        display: flex;
        font-size: 1.8em;
        text-align: center;
        width: 100%;
      }
    `};
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
  top: -2px;
`;
